import React from "react";

function PrivacyPage() {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      backgroundColor: "#070312",
      position: "relative",
      padding: "20px",
    },
    content: {
      zIndex: 2,
      padding: "20px",
      backgroundColor: "#070312",
      borderRadius: "15px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
      textAlign: "center",
      maxWidth: "800px",
      width: "100%",
    },
    header: {
      fontSize: "40px",
      color: "white",
      fontWeight: 700,
      marginBottom: "20px",
    },
    subheader: {
      fontSize: "24px",
      color: "#D32F53",
      marginBottom: "10px",
    },
    paragraph: {
      fontSize: "14px",
      color: "#AAAAAA",
      marginBottom: "20px",
      textAlign: "left",
    },
    link: {
      color: "#ffffff",
      textDecoration: "underline",
      fontWeight: "700",
    },
    footer: {
      marginTop: "50px",
      textAlign: "center",
      fontSize: "11px",
      color: "rgba(255, 255, 255, 0.7)",
    },
    navLinks: {
      position: "absolute",
      top: "20px",
      left: "50%",
      transform: "translateX(-50%)",
      color: "rgba(255, 255, 255, 0.7)",
      fontSize: "10px",
      zIndex: 4,
      display: "flex",
      gap: "10px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.navLinks}>
        <a href="/" style={styles.link}>
          Home
        </a>
        <a href="/terms" style={styles.link}>
          Terms of Service
        </a>
        <a href="/privacy" style={styles.link}>
          Privacy Policy
        </a>
      </div>
      <div style={styles.content}>
        <div style={styles.header}>Privacy Policy</div>
        <div style={styles.paragraph}>
          <p>
            At Bijou, we value your privacy and are committed to protecting your
            personal information. This privacy policy outlines how we collect,
            use, and safeguard your data when you use our application.
          </p>
          <h3 style={styles.subheader}>Information We Collect</h3>
          <p>
            When you register for an account with Bijou, we collect the
            following information:
            <ul>
              <li>Username</li>
              <li>Email address</li>
              <li>
                Avatar image (if you choose to upload one, it is stored in a
                Firebase storage bucket)
              </li>
            </ul>
          </p>
          <h3 style={styles.subheader}>How We Use Your Information</h3>
          <p>
            We use your personal information to:
            <ul>
              <li>Provide and maintain our service</li>
              <li>Personalize your experience</li>
              <li>
                Communicate with you about updates and other relevant
                information
              </li>
              <li>Improve our application</li>
            </ul>
          </p>
          <h3 style={styles.subheader}>Data Storage and Security</h3>
          <p>
            Your personal information, including your username, email address,
            and avatar image, is stored securely on Firebase Authentication and
            Firebase Storage. We implement appropriate security measures to
            protect your data from unauthorized access, alteration, or
            disclosure.
          </p>
          <h3 style={styles.subheader}>Third-Party Services</h3>
          <p>
            We do not share your personal information with third-party services
            except as required by law or to protect our rights.
          </p>
          <h3 style={styles.subheader}>Changes to This Privacy Policy</h3>
          <p>
            We may update this privacy policy from time to time. We will notify
            you of any changes by posting the new privacy policy on this page.
            You are advised to review this privacy policy periodically for any
            changes.
          </p>
          <h3 style={styles.subheader}>Contact Us</h3>
          <p>
            If you have any questions about this privacy policy, please contact
            us at{" "}
            <a href="mailto:curtis@getbijou.app" style={styles.link}>
              curtis@getbijou.app
            </a>
            .
          </p>
        </div>
        <div style={styles.footer}>
          <p>
            The Bijou application uses The Movie Database (TMDb) API but is not
            endorsed or certified by TMDb.
          </p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPage;
