import React from "react";

function TermsPage() {
  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      backgroundColor: "#070312",
      position: "relative",
      padding: "20px",
    },
    content: {
      zIndex: 2,
      padding: "20px",
      backgroundColor: "#070312",
      borderRadius: "15px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
      textAlign: "center",
      maxWidth: "800px",
      width: "100%",
    },
    header: {
      fontSize: "40px",
      color: "white",
      fontWeight: 700,
      marginBottom: "20px",
    },
    subheader: {
      fontSize: "24px",
      color: "#D32F53",
      marginBottom: "10px",
    },
    paragraph: {
      fontSize: "14px",
      color: "#AAAAAA",
      marginBottom: "20px",
      textAlign: "left",
    },
    link: {
      color: "#ffffff",
      textDecoration: "underline",
      fontWeight: "700",
    },
    footer: {
      marginTop: "50px",
      textAlign: "center",
      fontSize: "11px",
      color: "rgba(255, 255, 255, 0.7)",
    },
    navLinks: {
      position: "absolute",
      top: "20px",
      left: "50%",
      transform: "translateX(-50%)",
      color: "rgba(255, 255, 255, 0.7)",
      fontSize: "10px",
      zIndex: 4,
      display: "flex",
      gap: "10px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.navLinks}>
        <a href="/" style={styles.link}>
          Home
        </a>
        <a href="/terms" style={styles.link}>
          Terms of Service
        </a>
        <a href="/privacy" style={styles.link}>
          Privacy Policy
        </a>
      </div>
      <div style={styles.content}>
        <div style={styles.header}>Terms of Service</div>
        <div style={styles.paragraph}>
          <p>
            These terms of service outline the rules and regulations for the use
            of our application.
          </p>
          <h3 style={styles.subheader}>Acceptance of Terms</h3>
          <p>
            By accessing or using our application, you agree to be bound by
            these terms of service and our privacy policy. If you do not agree
            to these terms, please do not use our application.
          </p>
          <h3 style={styles.subheader}>User Accounts</h3>
          <p>
            To access and use our application, you are required to create an
            account. You agree to provide accurate, current, and complete
            information during the registration process, which includes your
            username and email address. Your username is public and must not be
            offensive, racist, homophobic, or otherwise inappropriate. Accounts
            found in violation of this rule will be deleted without warning. You
            can reset your password via the login page. To delete your account
            and all associated data, you can use the settings option within the
            app or contact us at{" "}
            <a href="mailto:curtis@getbijou.app" style={styles.link}>
              curtis@getbijou.app
            </a>{" "}
            with the subject header 'DELETE ACCOUNT [email] [username]'.
          </p>
          <h3 style={styles.subheader}>User Content</h3>
          <p>
            The only user-generated content in our application is the avatar
            image you may choose to upload. Your avatar must not contain
            inappropriate, illegal, or copyrighted imagery. Accounts with
            avatars found in violation of this rule will be deleted without
            warning.
          </p>
          <h3 style={styles.subheader}>Intellectual Property</h3>
          <p>
            All content and materials on our application, including but not
            limited to text, graphics, logos, and software, are the property of
            Bijou or its licensors and are protected by copyright, trademark,
            and other intellectual property laws. Bijou uses The Movie Database
            (TMDb) API for all information related to movies, TV shows, cast,
            and streaming services. All imagery and trademarked phrases are the
            explicit property of their original owners, and Bijou does not claim
            to own any of this content.
          </p>
          <h3 style={styles.subheader}>Termination</h3>
          <p>
            We may terminate or suspend your account and access to our
            application at any time, without prior notice or liability, for any
            reason whatsoever, including without limitation if you breach these
            terms.
          </p>
          <h3 style={styles.subheader}>Changes to Terms</h3>
          <p>
            We reserve the right to modify or replace these terms at any time.
            We will notify you of any changes by posting the new terms on this
            page. You are advised to review these terms periodically for any
            changes.
          </p>
          <h3 style={styles.subheader}>Contact Us</h3>
          <p>
            If you have any questions about these terms, please contact us at{" "}
            <a href="mailto:curtis@getbijou.app" style={styles.link}>
              curtis@getbijou.app
            </a>
            .
          </p>
        </div>
        <div style={styles.footer}>
          <p>
            The Bijou application uses The Movie Database (TMDb) API but is not
            endorsed or certified by TMDb.
          </p>
        </div>
      </div>
    </div>
  );
}

export default TermsPage;
