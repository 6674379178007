const API_BASE_URL = "https://api.getbijou.app";

export const fetchUserByName = async (name) => {
  const response = await fetch(`${API_BASE_URL}/api/proxy`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ endpoint: "getUserByName", payload: { name } }),
  });
  const data = await response.json();
  return data;
};

export const fetchListById = async (id) => {
  const response = await fetch(`${API_BASE_URL}/api/proxy`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ endpoint: "getListById", payload: { id } }),
  });
  const data = await response.json();
  return data;
};
