import React, { useState } from "react";

function LandingPage() {
  const [hovered, setHovered] = useState({
    badge: false,
    developedBy: false,
    aboutApp: false,
    signInButton: false,
    termsLink: false,
    privacyLink: false,
    contactLink: false,
    photoLink: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [showSignupModal, setShowSignupModal] = useState(false);
  const [email, setEmail] = useState("");
  const [platform, setPlatform] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [validationMessage, setValidationMessage] = useState("");

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "100vh",
      backgroundImage: "url(/background.jpg)",
      backgroundSize: "cover",
      backgroundPosition: "center",
      position: "relative",
      padding: "20px",
      boxSizing: "border-box",
      overflow: "hidden",
    },
    overlay: {
      backgroundColor: "rgba(13, 13, 25, 0.75)",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 1,
      backdropFilter: "blur(5px)",
    },
    secondaryOverlay: {
      backgroundColor: "rgba(13, 13, 25, 0.75)",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 4,
      backdropFilter: "blur(10px)",
      transition: "opacity 0.3s ease-in-out",
      opacity: showModal || showSignupModal ? 1 : 0,
    },
    content: {
      position: "relative",
      zIndex: 2,
      padding: "20px",
      backgroundColor: "#070312",
      borderRadius: "15px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
      textAlign: "center",
      maxWidth: "600px",
      margin: "0 auto",
      border: "1px solid",
      borderColor: "#D32F53",
    },
    badge: {
      display: "inline-block",
      marginTop: "5px",
      padding: "5px 10px",
      borderRadius: "20px",
      border: "1px solid",
      borderColor: "#FFD700",
      color: "#FFD700",
      fontSize: "9px",
      fontWeight: "bold",
      transition: "background-color 0.3s, color 0.3s, transform 0.3s",
      backgroundColor: hovered.badge ? "#FFD700" : "transparent",
      color: hovered.badge ? "#070312" : "#FFD700",
      transform: hovered.badge ? "translateY(-2px)" : "translateY(0)",
    },
    logo: {
      width: "40px",
      height: "40px",
    },
    headerContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "10px",
    },
    header: {
      fontSize: "32px",
      color: "white",
      fontWeight: 700,
    },
    popupheader: {
      fontSize: "18px",
      color: "white",
      fontWeight: 700,
    },
    subheader: {
      fontSize: "20px",
      color: "#D32F53",
      marginBottom: "10px",
    },
    description: {
      fontSize: "14px",
      color: "#AAAAAA",
      marginBottom: "15px",
    },
    popupdescription: {
      fontSize: "13px",
      color: "#AAAAAA",
      marginBottom: "15px",
    },
    inputContainer: {
      display: "flex",
      alignItems: "center",
      marginBottom: "15px",
      gap: "10px",
    },
    input: {
      height: "35px",
      borderColor: "#D32F53",
      borderWidth: "2px",
      borderRadius: "10px",
      padding: "0 10px",
      color: "#ffffff",
      backgroundColor: "rgba(11, 18, 89, 0.3)",
      width: "60%",
      outline: "none",
    },
    checkboxContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: "10px",
    },
    checkboxLabel: {
      color: "#ffffff",
      fontSize: "14px",
      display: "flex",
      alignItems: "center",
      gap: "5px",
      cursor: "pointer",
    },
    hiddenCheckbox: {
      position: "absolute",
      opacity: 0,
      cursor: "pointer",
      height: 0,
      width: 0,
    },
    customCheckbox: {
      width: "20px",
      height: "20px",
      borderRadius: "5px",
      border: "2px solid #D32F53",
      backgroundColor: "transparent",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
    },
    customCheckboxChecked: {
      backgroundColor: "#D32F53",
      borderColor: "#D32F53",
    },
    checkmark: {
      width: "10px",
      height: "10px",
      backgroundColor: "#fff",
      borderRadius: "3px",
    },
    signInButton: {
      backgroundColor: "#D32F53",
      padding: "6px 10px",
      borderRadius: "10px",
      color: "white",
      border: "none",
      cursor: "pointer",
      flexShrink: 0,
      transition: "transform 0.3s",
      transform: hovered.signInButton ? "translateY(-2px)" : "translateY(0)",
    },
    buttonContainer: {
      display: "flex",
      justifyContent: "center",
      gap: "10px",
      marginBottom: "15px",
    },
    storeButton: {
      width: "120px",
      height: "auto",
      cursor: "pointer",
      marginRight: 25,
      marginLeft: 25,
      marginBottom: 15,
      marginTop: 15,
      transition: "transform 0.3s",
      transform: hovered.storeButton ? "translateY(-2px)" : "translateY(0)",
    },
    footer: {
      marginTop: "15px",
      textAlign: "center",
      fontSize: "10px",
      color: "rgba(255, 255, 255, 0.7)",
    },
    divider: {
      width: "80%",
      height: "1px",
      backgroundColor: "rgba(255, 255, 255, 0.2)",
      margin: "15px auto",
    },
    link: {
      color: "#ffffff",
      textDecoration: "underline",
      fontWeight: "700",
      transition: "color 0.3s",
    },
    linkHovered: {
      color: "#D32F53",
      textDecoration: "underline",
      fontWeight: "700",
    },
    tmdbLogo: {
      width: "60px",
      height: "auto",
      marginBottom: "5px",
      resizeMode: "contain",
    },
    credits: {
      position: "relative",
      textAlign: "center",
      fontSize: "9px",
      color: "rgba(255, 255, 255, 0.7)",
      zIndex: 3,
      marginBottom: "10px",
    },
    developedBy: {
      display: "inline-block",
      padding: "5px 10px",
      borderRadius: "20px",
      border: "1px solid",
      borderColor: "#00ADEF",
      color: "#00ADEF",
      fontSize: "9px",
      fontWeight: "bold",
      backgroundColor: "rgba(13, 13, 25, 0.75)",
      zIndex: 4,
      cursor: "pointer",
      textDecoration: "none",
      transition: "background-color 0.3s, color 0.3s, transform 0.3s",
      backgroundColor: hovered.developedBy ? "#00ADEF" : "transparent",
      color: hovered.developedBy ? "#070312" : "#00ADEF",
      transform: hovered.developedBy ? "translateY(-2px)" : "translateY(0)",
      marginRight: "10px",
    },
    aboutApp: {
      display: "inline-block",
      padding: "5px 10px",
      borderRadius: "20px",
      border: "1px solid",
      borderColor: "#00FF7F",
      color: "#00FF7F",
      fontSize: "9px",
      fontWeight: "bold",
      backgroundColor: "rgba(13, 13, 25, 0.75)",
      zIndex: 4,
      cursor: "pointer",
      textDecoration: "none",
      transition: "background-color 0.3s, color 0.3s, transform 0.3s",
      backgroundColor: hovered.aboutApp ? "#00FF7F" : "transparent",
      color: hovered.aboutApp ? "#070312" : "#00FF7F",
      transform: hovered.aboutApp ? "translateY(-2px)" : "translateY(0)",
    },
    modal: {
      position: "fixed",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      zIndex: 5,
      backgroundColor: "#070312",
      borderRadius: "15px",
      boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
      textAlign: "center",
      maxWidth: "600px",
      width: "80%",
      padding: "20px",
      border: "2px solid white",
      transition: "opacity 0.3s ease-in-out",
      opacity: showModal || showSignupModal ? 1 : 0,
    },
    closeButton: {
      position: "absolute",
      top: "10px",
      right: "10px",
      background: "none",
      border: "none",
      color: "#FFD700",
      fontSize: "16px",
      cursor: "pointer",
    },
    termsPrivacyLinks: {
      display: "flex",
      gap: "10px",
      justifyContent: "center",
    },
    badgeContainer: {
      position: "absolute",
      top: "20px",
      left: "50%",
      transform: "translateX(-50%)",
      display: "flex",
      gap: "10px",
      zIndex: 4,
    },
    "@media (max-width: 600px)": {
      container: {
        padding: "10px",
      },
      overlay: {
        backgroundColor: "rgba(13, 13, 25, 0.5)",
      },
      content: {
        width: "90%",
        padding: "10px",
      },
      header: {
        fontSize: "28px",
      },
      subheader: {
        fontSize: "18px",
      },
      description: {
        fontSize: "11px",
      },
      footer: {
        fontSize: "9px",
      },
      tmdbLogo: {
        width: "70px",
      },
      credits: {
        fontSize: "9px",
      },
      modal: {
        width: "90%",
        top: "80px",
      },
      inputContainer: {
        flexDirection: "column",
        alignItems: "stretch",
        gap: "10px",
      },
      input: {
        width: "100%",
        marginBottom: "10px",
      },
      checkboxContainer: {
        justifyContent: "center",
      },
      signInButton: {
        width: "100%",
      },
    },
  };

  const handleSubmit = async () => {
    if (email === "") {
      setValidationMessage("Email field cannot be blank");
      return;
    }
    if (platform === "") {
      setValidationMessage("Please select a platform");
      return;
    }

    const formData = new FormData();
    formData.append("entry.654838748", email); // Email entry ID
    formData.append("entry.300616312", platform); // Platform entry ID

    try {
      await fetch(
        "https://docs.google.com/forms/d/e/1FAIpQLSdj_daV87rM3ty-PXe2BFETyOF3RRA7u4E_mRiQN5DvnWuwBg/formResponse",
        {
          method: "POST",
          body: formData,
          mode: "no-cors",
        }
      );
      setIsSubmitted(true);
      setValidationMessage(
        "Thank you for signing up! We will notify you when the beta is ready."
      );
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.overlay}></div>
      <div style={styles.badgeContainer}>
        <a
          href="https://crtyx.io"
          style={styles.developedBy}
          onMouseEnter={() => setHovered({ ...hovered, developedBy: true })}
          onMouseLeave={() => setHovered({ ...hovered, developedBy: false })}
        >
          passion project by crtyx
        </a>
        <a
          style={styles.aboutApp}
          onClick={() => setShowModal(true)}
          onMouseEnter={() => setHovered({ ...hovered, aboutApp: true })}
          onMouseLeave={() => setHovered({ ...hovered, aboutApp: false })}
        >
          about the app
        </a>
      </div>
      <div style={styles.content}>
        <div
          style={styles.badge}
          onMouseEnter={() => setHovered({ ...hovered, badge: true })}
          onMouseLeave={() => setHovered({ ...hovered, badge: false })}
        >
          NO ADS. NO NONSENSE.
        </div>
        <div style={styles.headerContainer}>
          <img src="/bijou_three.png" alt="Bijou Logo" style={styles.logo} />
        </div>
        <div style={styles.header}>Bijou</div>
        <div style={styles.subheader}>
          Your favourite actor's favourite app.
        </div>
        <div style={styles.description}>
          From creating watchlists and exploring general trivia to receiving
          AI-powered recommendations based on your viewing history, Bijou offers
          up-to-date information on where to stream and provides you with core
          social features like shareable lists and the ability to follow your
          favourite actors, influencers, and friends! Free of charge. No ads. No
          nonsense.
        </div>
        <button
          style={styles.signInButton}
          onClick={() => setShowSignupModal(true)}
          onMouseEnter={() => setHovered({ ...hovered, signInButton: true })}
          onMouseLeave={() => setHovered({ ...hovered, signInButton: false })}
        >
          Sign Up for Beta Access
        </button>
        {isSubmitted && (
          <div style={styles.description}>
            Thank you for signing up! We will notify you when the beta is ready.
          </div>
        )}
        <div style={styles.footer}>
          <p>
            Contact (for red carpet/premiere/event invitations 👉🥹👈){" "}
            <a
              href="mailto:curtis@getbijou.app"
              style={hovered.contactLink ? styles.linkHovered : styles.link}
              onMouseEnter={() => setHovered({ ...hovered, contactLink: true })}
              onMouseLeave={() =>
                setHovered({ ...hovered, contactLink: false })
              }
            >
              curtis@getbijou.app
            </a>
          </p>
          <p>Made with 💕 for Sophie x</p>
        </div>
        <div style={styles.divider}></div>
        <div style={styles.credits}>
          <img src="/tmdb.png" alt="TMDB Logo" style={styles.tmdbLogo} />
          <p>
            This application uses the The Movie Database API but is not endorsed
            or certified by TMDb.
          </p>
          <p>
            Photo by{" "}
            <a
              href="https://www.pexels.com/photo/a-couple-watching-a-movie-in-a-cinema-7991574/"
              target="_blank"
              rel="noopener noreferrer"
              style={hovered.photoLink ? styles.linkHovered : styles.link}
              onMouseEnter={() => setHovered({ ...hovered, photoLink: true })}
              onMouseLeave={() => setHovered({ ...hovered, photoLink: false })}
            >
              Tima Miroshnichenko
            </a>
          </p>
          <div style={styles.termsPrivacyLinks}>
            <a
              href="/terms"
              style={hovered.termsLink ? styles.linkHovered : styles.link}
              onMouseEnter={() => setHovered({ ...hovered, termsLink: true })}
              onMouseLeave={() => setHovered({ ...hovered, termsLink: false })}
            >
              Terms of Service
            </a>
            <a
              href="/privacy"
              style={hovered.privacyLink ? styles.linkHovered : styles.link}
              onMouseEnter={() => setHovered({ ...hovered, privacyLink: true })}
              onMouseLeave={() =>
                setHovered({ ...hovered, privacyLink: false })
              }
            >
              Privacy Policy
            </a>
          </div>
        </div>
      </div>
      {(showModal || showSignupModal) && (
        <div style={styles.secondaryOverlay}></div>
      )}
      {showModal && (
        <div style={styles.modal}>
          <button
            style={styles.closeButton}
            onClick={() => setShowModal(false)}
          >
            &times;
          </button>
          <div style={styles.popupheader}>About</div>
          <div style={styles.popupdescription}>
            Bijou is dedicated to my fiancee, Sophie. This application was
            developed for her as a replacement for many of the alternatives in
            the market as I simply felt they had an outdated UI or focused too
            much on US streaming services. This was a fun little project which I
            originally had no intention to share beyond friends/family - I hope
            you enjoy it!
          </div>
        </div>
      )}
      {showSignupModal && (
        <div style={styles.modal}>
          <button
            style={styles.closeButton}
            onClick={() => {
              setShowSignupModal(false);
              setValidationMessage("");
            }}
          >
            &times;
          </button>
          <div style={styles.popupheader}>Sign Up for Beta Access</div>
          <div style={styles.popupdescription}>
            Enter your email and platform preference to sign up for beta access.
          </div>
          {validationMessage && (
            <div
              style={{
                ...styles.popupdescription,
                color: isSubmitted ? "#00FF7F" : "#FF6B81",
              }}
            >
              {validationMessage}
            </div>
          )}
          {!isSubmitted && (
            <div
              style={{
                ...styles.inputContainer,
                flexDirection: "column",
                gap: "10px",
              }}
            >
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={styles.input}
                onFocus={(e) => (e.target.style.borderColor = "#FF6B81")}
                onBlur={(e) => (e.target.style.borderColor = "#D32F53")}
              />
              <div style={styles.checkboxContainer}>
                <label style={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    name="platform"
                    value="iOS"
                    checked={platform === "iOS"}
                    onChange={() => setPlatform("iOS")}
                    style={styles.hiddenCheckbox}
                  />
                  <div
                    style={{
                      ...styles.customCheckbox,
                      ...(platform === "iOS"
                        ? styles.customCheckboxChecked
                        : {}),
                    }}
                  >
                    {platform === "iOS" && <div style={styles.checkmark}></div>}
                  </div>
                  iOS
                </label>
                <label style={styles.checkboxLabel}>
                  <input
                    type="checkbox"
                    name="platform"
                    value="Android"
                    checked={platform === "Android"}
                    onChange={() => setPlatform("Android")}
                    style={styles.hiddenCheckbox}
                  />
                  <div
                    style={{
                      ...styles.customCheckbox,
                      ...(platform === "Android"
                        ? styles.customCheckboxChecked
                        : {}),
                    }}
                  >
                    {platform === "Android" && (
                      <div style={styles.checkmark}></div>
                    )}
                  </div>
                  Android
                </label>
              </div>
              <button
                style={styles.signInButton}
                onClick={handleSubmit}
                onMouseEnter={() =>
                  setHovered({ ...hovered, signInButton: true })
                }
                onMouseLeave={() =>
                  setHovered({ ...hovered, signInButton: false })
                }
              >
                Submit
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default LandingPage;
