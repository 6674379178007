import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { getUserByName } from "../api/user";
import axios from "axios";

function UserProfile() {
  const { name } = useParams();
  const [userData, setUserData] = useState(null);
  const [watchlistCount, setWatchlistCount] = useState(0);
  const [watchedCount, setWatchedCount] = useState(0);
  const [ratedCount, setRatedCount] = useState(0);
  const [followersCount, setFollowersCount] = useState(0);
  const [followingCount, setFollowingCount] = useState(0);
  const [topMovies, setTopMovies] = useState([]);
  const [topTVShows, setTopTVShows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await getUserByName(name);
        if (!data) {
          throw new Error("User not found");
        }
        setUserData(data);
        setWatchlistCount(data.watchlist?.length || 0);
        setWatchedCount(data.watched?.length || 0);
        setRatedCount(data.rated?.length || 0);
        setFollowersCount(data.followers?.length || 0);
        setFollowingCount(data.following?.length || 0);
        fetchAdditionalData(data.topMovies || [], setTopMovies);
        fetchAdditionalData(data.topTVShows || [], setTopTVShows);
      } catch (error) {
        console.error("Error fetching user data:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [name]);

  const fetchAdditionalData = async (items, setState) => {
    try {
      const detailedItems = await Promise.all(
        items.map(async (item) => {
          try {
            const mediaType =
              item.media_type || (await verifyMediaType(item.id, item.title));
            if (!mediaType) {
              console.error(
                `Media type for ID ${item.id} could not be determined.`
              );
              return null;
            }

            console.log(`Fetching details for ${mediaType} with ID ${item.id}`);
            const itemDetails = await fetchItemDetails(item.id, mediaType);
            return {
              ...itemDetails,
              media_type: mediaType,
              userRating: item.rating || null,
            };
          } catch (error) {
            console.error(`Error fetching data for ID ${item.id}:`, error);
            return null;
          }
        })
      );
      console.log("Detailed items:", detailedItems);
      setState(detailedItems.filter((item) => item !== null));
    } catch (error) {
      console.error("Error fetching additional data:", error);
    }
  };

  const fetchItemDetails = async (id, mediaType) => {
    try {
      const response = await axios.post("https://api.getbijou.app/api/tmdb/web", {
        id,
        mediaType,
      });
      return { ...response.data, media_type: mediaType };
    } catch (error) {
      console.error(`Error fetching ${mediaType} details for ID ${id}:`, error);
      return null;
    }
  };

  const verifyMediaType = async (id, expectedName) => {
    try {
      const response = await axios.post("https://api.getbijou.app/api/tmdb", {
        id,
        mediaType: "movie",
      });
      if (response.data.title === expectedName) {
        return "movie";
      }
    } catch (error) {
      if (error.response && error.response.status !== 404) {
        console.error(`Error verifying as movie for ID ${id}:`, error);
      }
    }

    try {
      const response = await axios.post("https://api.getbijou.app/api/tmdb", {
        id,
        mediaType: "tv",
      });
      if (response.data.name === expectedName) {
        return "tv";
      }
    } catch (error) {
      if (error.response && error.response.status !== 404) {
        console.error(`Error verifying as TV show for ID ${id}:`, error);
      }
    }

    return null;
  };

  const getBadgeText = (badge) => {
    switch (badge) {
      case "Admin":
        return "👑 DEVELOPER";
      case "VIP":
        return "✨ VERIFIED VIP";
      case "BETA":
        return "🧪 BETA TESTER";
      case "Sophie":
        return "💖 THE SOPHIE";
      default:
        return badge ? badge.toUpperCase() : "";
    }
  };

  const getBadgeStyle = (badge) => {
    switch (badge) {
      case "Admin":
        return "adminBadge";
      case "VIP":
        return "vipBadge";
      case "BETA":
        return "betaBadge";
      case "Sophie":
        return "sophieBadge";
      default:
        return "defaultBadge";
    }
  };

  if (loading) return <div>Loading...</div>;

  if (error) {
    return (
      <div style={styles.container}>
        <div style={styles.overlay}></div>
        <div style={styles.content}>
          <h1 style={styles.userName}>User not found!</h1>
          <p style={styles.registrationDate}>
            Please check your URL and try again
          </p>
          <a href="/" style={styles.downloadButton}>
            Download Bijou
          </a>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.container}>
      <div style={styles.overlay}></div>
      <a href="/" style={styles.banner}>
        Download Bijou
      </a>
      <div style={styles.content}>
        <div style={styles.profileHeader}>
          <img
            src={
              userData.avatar ? userData.avatar : "/assets/placeholder_icon.png"
            }
            alt="Avatar"
            style={styles.avatar}
          />
          <h1 style={styles.userName}>{userData.name}</h1>
          {userData.badge && (
            <p
              style={{
                ...styles.badge,
                ...styles[getBadgeStyle(userData.badge)],
              }}
            >
              {getBadgeText(userData.badge)}
            </p>
          )}
          <p style={styles.registrationDate}>
            Member since{" "}
            {new Date(userData.registrationDate).toLocaleDateString("en-US", {
              year: "numeric",
              month: "long",
            })}
          </p>
          <div style={styles.statsContainer}>
            <div style={styles.statItem}>
              <p style={styles.statNumber}>{watchlistCount}</p>
              <p style={styles.statText}>Watchlist</p>
            </div>
            <div style={styles.verticalDivider}></div>
            <div style={styles.statItem}>
              <p style={styles.statNumber}>{watchedCount}</p>
              <p style={styles.statText}>Watched</p>
            </div>
            <div style={styles.verticalDivider}></div>
            <div style={styles.statItem}>
              <p style={styles.statNumber}>{ratedCount}</p>
              <p style={styles.statText}>Rated</p>
            </div>
            <div style={styles.verticalDivider}></div>
            <div style={styles.statItem}>
              <p style={styles.statNumber}>{followersCount}</p>
              <p style={styles.statText}>Followers</p>
            </div>
            <div style={styles.verticalDivider}></div>
            <div style={styles.statItem}>
              <p style={styles.statNumber}>{followingCount}</p>
              <p style={styles.statText}>Following</p>
            </div>
          </div>
        </div>
        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>Top 3 Movies</h2>
          <div style={styles.listContainer}>
            {topMovies.map((movie) => (
              <div key={movie.id} style={styles.listItem}>
                <img
                  src={`https://image.tmdb.org/t/p/w200${movie.poster_path}`}
                  alt={movie.title}
                  style={styles.listItemImage}
                />
                <p style={styles.listItemTitle}>{movie.title}</p>
              </div>
            ))}
          </div>
        </div>
        <div style={styles.section}>
          <h2 style={styles.sectionTitle}>Top 3 TV Shows</h2>
          <div style={styles.listContainer}>
            {topTVShows.map((tvShow) => (
              <div key={tvShow.id} style={styles.listItem}>
                <img
                  src={`https://image.tmdb.org/t/p/w200${tvShow.poster_path}`}
                  alt={tvShow.name}
                  style={styles.listItemImage}
                />
                <p style={styles.listItemTitle}>{tvShow.name}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

const styles = {
  container: {
    minHeight: "100vh",
    backgroundImage: "url(/background.jpg)",
    backgroundSize: "cover",
    backgroundPosition: "center",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "30px",
    boxSizing: "border-box",
    overflow: "hidden",
  },
  overlay: {
    backgroundColor: "rgba(13, 13, 25, 0.75)",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    backdropFilter: "blur(5px)",
  },
  banner: {
    position: "fixed",
    top: "30px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "#D32F53",
    color: "#fff",
    padding: "8px",
    borderRadius: "5px",
    textDecoration: "none",
    zIndex: 3,
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
    fontWeight: "bold",
    fontSize: 12,
  },
  content: {
    position: "relative",
    zIndex: 2,
    padding: "20px",
    backgroundColor: "#070312",
    borderRadius: "15px",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
    textAlign: "center",
    maxWidth: "400px",
    width: "100%",
    border: "1px solid",
    borderColor: "#D32F53",
  },
  profileHeader: {},
  avatar: {
    width: "60px",
    height: "60px",
    borderRadius: "50%",
    objectFit: "cover",
    marginBottom: "5px",
    marginTop: 0,
  },
  userName: {
    color: "#FFF",
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "5px",
  },
  badge: {
    fontSize: "10px",
    padding: "2px 5px",
    borderRadius: "20px",
    border: "1px solid",
    display: "inline-block",
    marginTop: "2px",
  },
  adminBadge: {
    borderColor: "#FFD700",
    color: "#FFD700",
  },
  vipBadge: {
    borderColor: "#EF575B",
    color: "#EF575B",
  },
  betaBadge: {
    borderColor: "#77F783",
    color: "#77F783",
  },
  sophieBadge: {
    borderColor: "#FF80DB",
    color: "#FF80DB",
  },
  defaultBadge: {
    borderColor: "#AAA",
    color: "#AAA",
  },
  registrationDate: {
    color: "rgba(255, 255, 255, 0.6)",
    fontSize: "14px",
    marginTop: "2px",
    marginBottom: "10px",
  },
  statsContainer: {
    display: "flex",
    justifyContent: "space-around",
    width: "100%",
    marginBottom: "20px",
    alignItems: "center",
  },
  statItem: {
    alignItems: "center",
    flex: 1,
  },
  statNumber: {
    color: "#FFF",
    fontSize: "14px",
    fontWeight: "bold",
  },
  statText: {
    color: "#FFF",
    fontSize: "10px",
  },
  verticalDivider: {
    height: "40px",
    width: "1px",
    backgroundColor: "rgba(255, 255, 255, 0.2)",
    margin: "0 10px",
  },
  section: {},
  sectionTitle: {
    color: "#FFF",
    fontSize: "16px",
    fontWeight: "bold",
    marginBottom: "20px",
  },
  listContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: "30px",
  },
  listItem: {
    width: "calc(33.333% - 20px)",
    textAlign: "center",
  },
  listItemImage: {
    width: "100%",
    borderRadius: "10px",
  },
  listItemTitle: {
    color: "#FFF",
    fontSize: "12px",
    marginTop: "10px",
  },
  downloadButton: {
    display: "inline-block",
    backgroundColor: "#D32F53",
    color: "#FFF",
    padding: "10px 20px",
    borderRadius: "5px",
    textDecoration: "none",
    marginTop: "20px",
    fontWeight: "bold",
  },
};

export default UserProfile;
