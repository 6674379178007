import { fetchUserByName } from "./proxyHandler";

export const getUserByName = async (name) => {
  try {
    const userData = await fetchUserByName(name);
    return userData;
  } catch (error) {
    console.error("Error fetching user data:", error);
    throw error;
  }
};
