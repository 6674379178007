import { fetchListById } from "./proxyHandler";

export const getListById = async (id) => {
  try {
    const listData = await fetchListById(id);
    return listData;
  } catch (error) {
    console.error("Error fetching list data:", error);
    throw error;
  }
};
